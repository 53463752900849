p {
  max-width: 50rem;
  hyphens: auto;

  strong {
    color: theme-color("primary");
  }
}

.text-white, .bg-primary {
  strong {
    color: $white;
  }
}

.bg-primary {
  a {
    color: $white;

    &:focus, &:hover {
      color: $link-hover-color;
  }
  }
}

.text-black {
  color: $black !important;
}

body {
  @include media-breakpoint-up(md) {
    line-height: $line-height-md;
  }
}

form legend {
  display: none;
}
