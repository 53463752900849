$color_1: #ffffff;
$background-color_1: #e6000a;

.--sticky_btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 140px;
  z-index: 1000;
  transition: all .4s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  a {
    color: $color_1;
  }
  a.btn-sticky {
    min-height: 60px;
    span {
      display: block;
      padding: 1.2em 45px 1em 1em;
      font-size: 15px;
      font-weight: bold;
    }
  }
  a.btn-sticky-top {
    .btn-more--arrow {
      position: absolute;
      right: 15px;
      bottom: 26px;
      display: block;
      margin: auto;
      margin-top: auto;
      top: 50%;
      bottom: unset;
      transform: translateY(-50%);
      width: 1.4375em;
      height: 1.25em;
    }
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    z-index: 1001;
    span {
      display: block;
      padding: 1.2em 45px 1em 1em;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
.sticky_btn_text_bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  transform: skewY(-5deg);
  background-color: $background-color_1;
  z-index: 1;
  transform: skewY(-5deg);
  transition: all .4s ease-in-out;
}

@media (max-width: 768px) {
  .--sticky_btn a.btn-sticky-top .btn-more--arrow {
    top: 45%;
  }
}
