.splide__arrow {
  background: theme-color("secondary");

  svg {
    fill: $white;
  }
}

.splide__pagination__page {
  &.is-active {
    background: theme-color("secondary");
  }
}

.mprofi-slide {
  &__infobox {
    &__content-wrapper {
      width: 75%;

      @include media-breakpoint-up(sm) {
        width: 22.5rem;
      }

      @include media-breakpoint-up(md) {
        top: 5.75rem;
        bottom: 5.75rem;
      }

      @include media-breakpoint-up(lg) {
        top: 5.75rem;
        bottom: 5.75rem;
        width: 28rem;
      }
    }

    &__content {
      overflow: hidden;

      h1 {
        color: #fff;
      }
    }

    &__background {
      transform: skew(0deg, -5deg);
      @include media-breakpoint-up(md) {
        width: 28rem;
      }
    }
  }
}

.mprofi_splide--do-not-initiate {
  padding-bottom: 0px !important;
}


.hawa-news-kit {

  &.card-slider-outer {
    @media only screen and (max-width: 576px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .splide {
    &__arrow {
        top: 50%;
        bottom: auto;

      @media only screen and (max-width: 576px) {
          height: 25px;
          width: 25px;
          svg {
            height: 25px;
            min-height: 25px;
            width: 14px;
          }

          &.splide__arrow--next {
            margin-right: -25px !important;
          }
          &.splide__arrow--prev {
            margin-left: -25px !important;
          }
        }
    }
  }
}
