.hawa-navbar {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(lg) {
    padding-top: $navbar-padding-y * 2;
  }

  .navbar-toggler {
    position: absolute;
    top: 0;
    right: 0;
    height: 69px;
    border-left: 1px solid gray("300");
    border-radius: 0;
    padding: ($navbar-brand-padding-y + $navbar-padding-y) 1.25rem;
    font-size: .6rem;
    color: theme-color("primary");

    &[aria-expanded="true"] {
      color: theme-color("secondary");
    }
  }

  .navbar-brand {
    padding-left: 0;
    padding-top: 0;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid gray("300");

    @include media-breakpoint-up(lg) {
      width: auto;
      border: none;
      padding-left: 0;
      padding-bottom: $navbar-padding-y * 2;
    }

    & > img {
      max-height: 49px;
      height: 49px;
      width: auto;
      max-width: fit-content;

      @include media-breakpoint-up(lg) {
        max-height: 104px;
        height: 104px;
      }
    }
  }
}

.liveSearchHeaderBox {
  position:absolute;
  width:100%;
  height:auto;
  opacity:1;
  background:#fff;
  left:0px;
  right:0px;
  padding-bottom: 3em;
  display: none;
  z-index: 1001;
  margin-top: -12px;
  padding-top: 12px;

  .aa-Input:focus {
    background-color: #FFF;
    border-color: transparent;
    outline: 0;
    box-shadow: none !important;
  }

  .aa-InputWrapperSuffix {
    display: none;
  }

  .aa-InputWrapperPrefix {
    align-items: flex-start;
    order: 4;
    background-color: #003c78;
    .aa-Label svg, .aa-LoadingIndicator svg {
      color: #ffffff;
      scale: 1.2;
    }
  }
  .aa-InputWrapper {
    input {
      padding-left: 16px;
    }
  }
  .aa-Form {
    border-radius: 0px;
  }
  .live-results {

  }
  .searchCloseButtonContainer {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    margin-bottom: 1.25em;
    height: auto;
    min-height: 60px;

    .searchCloseButton {
      position: absolute;
      right: 30px;
      top: 0px;
      width: 35px;
      height: 35px;
      background-color: transparent;
      color: #003c78;
      border: 0px;
      padding-top: 0em;

      svg {
        path {
          fill: #003c78;
        }
      }


      @include media-breakpoint-down(md) {
        position: relative;
        float: right;
        height: 45px;
        width: 45px;
        right: 0px;
      }

      svg {
        max-width: 20px;
        width: auto;
        height: auto;
      }

    }
  }

  @include media-breakpoint-down(md) {
    overflow-y: scroll;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  .liveSearchHeaderBox-container {
    @include media-breakpoint-down(md) {
      padding-top: 16px;
    }
  }

}

body {
  &.openLiveSearchHeaderBox {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }
}
