.project-list-item {
  margin-bottom: 70px;

  .project-list-item-inner {

    background-color: #e9eaeb;

    .referenceList__itemTextBlock {

      h3 {
        color: #003c78;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        min-height: 2.5rem;
      }

      .references-divider {
        background-color: #e30a13;
        width: 40px;
        height: 4px;
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .project-teaser-tags {
        color: #003c78;
        font-size: 16px;
        min-height: 2.5rem;
      }

      padding: 1.25rem;
      min-height: 115px;
    }

  }
}

.teaser-image-outer {
  width: 100%;
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.teaser-image-inner {
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  background-size: cover;
  transition: .5s all;
}

.teaser-image-inner:hover {
  transform:scale(1.1);
}

