h3 {
  &.prefooter-title {
    color: #fff !important;
    font-size: 2.33rem;
    font-weight: 700 !important;
  }
}

footer {
  position: relative;
  text-align: center;

  .socialbar {

    a {
      color: theme-color("primary");
    }

    &.list-group {
      justify-content: center;
    }

    .list-group-item {
      padding: 0 $spacer * 1.25 $spacer $spacer * 1.25;
    }
  }

  @include media-breakpoint-up(lg) {
    text-align: left;

    .socialbar {
      &.list-group {
        justify-content: flex-start;
      }
    }
  }

  .footer--pre {
    background-color: theme-color("primary");;
  }

  .footer--head {
    background-color: gray("200");
    position: relative;

    nav {
      font-size: $font-size-base * 1.125;
      font-weight: $font-weight-bold;
    }
  }

  .footer--base {
    background-color: theme-color("primary");
    color: color("white");
    text-align: center;

    .footer-language-nav {
      .nav {
        justify-content: center;
      }
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
      background-color: gray("200");
      color: theme-color("primary");

      .footer-language-nav {
        .nav {
          justify-content: flex-start;

          .nav-link {
            &.active {
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }

  .footer--sidebar {
    .list-with-icon {
      &.list-group {
        width: fit-content;
        margin: auto;

        @include media-breakpoint-up(lg) {
          width: 100%;
          margin: 0;
        }
      }

      .list-group-item {
        position: relative;
        font-size: $font-size-sm;
        color: theme-color("primary");
        line-height: $line-height-base;
        width: fit-content;
        margin: auto;

        @include media-breakpoint-up(lg) {
          width: 100%;
          margin: 0;
        }

        padding: 0 0 0 $spacer * 2.5;

        &.hawafonticon::before {
          position: absolute;
          left: 0;
          font-size: $font-size-base * 1.0625;
        }
      }
    }
  }

  .footer--navbar-bottom {
    font-weight: $font-weight-bold;
  }
}

.footer-btn-top {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNDcgNDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3IDQ3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I2U2MDAwYTt9Cgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxnIGlkPSJFYmVuZV8yXzAwMDAwMTY4ODE2MTE3MDYwODgwNjY4OTIwMDAwMDEzNTk4MjQ5ODE5NDE3MzMwMzQwXyI+Cgk8ZyBpZD0iRWJlbmVfMS0yIj4KCQk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyMy41IiBjeT0iMjMuNSIgcj0iMjMuNSIvPgoJCTxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iMjMuNSwxOSAzMS44LDI3LjMgMzIuNSwyNi43IDIzLjUsMTcuNyAxNC41LDI2LjcgMTUuMiwyNy4zIAkJIi8+CgkJPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIyMy41LDE3IDMzLjIsMjYuNyAzMS44LDI4IDIzLjUsMTkuNyAxNS4yLDI4IDEzLjgsMjYuNyAJCSIvPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=);
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  top: 0;
  right: $grid-gutter-width * .5;
  margin-top: -25px;
}
