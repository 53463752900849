.card-group {
  margin-bottom: 2.5rem;

  .card {
    max-width: 100%;
    flex: 1 0 100%;

    &.h-100 {
      height: auto !important;
    }

    .card-title {
      font-weight: $font-weight-normal;
    }

    picture, img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-up(sm) {
      max-width: 100%;
      flex: 1 0 100%;
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;
      flex: 1 0 100%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 100%;
      flex: 0 0 50%;
    }
  }
}

.footer--pre {
  .card-text,
  .card-title {
    text-align: left;
  }
}

.card-deck {

  @include media-breakpoint-down(sm) {
    .card-img-top {
      padding: 1.25rem 1.25rem 0 1.25rem;
    }
  }

  .bg-transparent, .bg-white {
    .card-img-top { padding: 0; }
  }

  .col {
    padding: 0;
    margin-bottom: $spacer * 2.5;
  }

  &.row-cols-md-2 {
    .card {
      picture, img {
        height: 367px;

        @include media-breakpoint-up(md) {
          height: 244px;
        }

        @include media-breakpoint-up(lg) {
          height: 334px;
        }

        @include media-breakpoint-up(xl) {
          height: 400px;
        }
      }
    }
  }

  &.row-cols-md-3 {
    .card {
      picture, img {
        height: 367px;

        @include media-breakpoint-up(md) {
          height: 158px;
        }

        @include media-breakpoint-up(lg) {
          height: 218px;
        }

        @include media-breakpoint-up(xl) {
          height: 263px;
        }
      }
    }
  }
}

.card {
  picture, img {
    width: 100%;
    object-fit: cover;
    height: 367px;

    @include media-breakpoint-up(md) {
      height: 159px;
    }

    @include media-breakpoint-up(lg) {
      height: 159px;
    }

    @include media-breakpoint-up(xl) {
      height: 193px;
    }
  }

  .card-left-right-arrow::before {
    content: "";
    z-index: $zindex-dropdown;
    position: absolute;
    height: 0;

    top: 0;
    left: 20px;
    width: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top:15px solid theme-color('lighter');
    border-bottom: none;
  }

  @include media-breakpoint-up(sm) {
    .order-sm-first {
      .card-left-right-arrow::before {
        top: 20px;
        left: auto;
        right: 0;
        width: 0;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
        border-right:15px solid theme-color('lighter');
        border-left: none;
      }
    }

    .order-sm-last {
      .card-left-right-arrow::before {
        top: 20px;
        left: 0;
        width: 0;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
        border-right: none;
        border-left:15px solid theme-color('lighter');
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .order-lg-first {
      .card-left-right-arrow::before {
        top: 20px;
        left: auto;
        right: 0;
        width: 0;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
        border-right:15px solid theme-color('lighter');
        border-left: none;
      }
    }

    .order-lg-last {
      .card-left-right-arrow::before {
        top: 20px;
        left: 0;
        width: 0;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
        border-right: none;
        border-left:15px solid theme-color('lighter');
      }
    }
  }


    // border-top: 7.5px solid transparent;
    // border-bottom: 7.5px solid transparent;
    // border-right:15px solid theme-color('lighter');

  .card-title {
    margin-bottom: 0;
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 17.655%;
      border-bottom: 4px solid theme-color('secondary');
      margin-top: $spacer * .625;
      margin-bottom: $spacer * .625;
    }
  }

  .card-body {
    margin-top: $spacer * 1.25;
    margin-bottom: 0;
  }

  &.card--background {
    .card-body {
      margin: $spacer * 1.25;
    }
    &.bg-transparent, &.bg-white {
      .card-body {
        @include media-breakpoint-up(sm) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.splide__slide {
  .card-deck {
    padding-left: $spacer * 4.375;
    padding-right: $spacer * 4.375;

    .card-news {
      .text-date {
        color: color("light-blue");
      }
    }
  }
}


.card-deck-slider {
  margin-bottom: 2.5rem;
  .splide__slide {
    min-height: 0px;
    height: auto;
    background-color: #e5e5e5 !important;
  }
}

.card-news,
.card-references {
  .card-date {

  }
  .card-title {
    color: color("blue");
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .card-text {
    color: color("blue");
  }
  .text-date {
    color: color("light-blue");
  }
}

.card-slider-outer {
  padding-left: 50px;
  padding-right: 50px;

  .splide__arrows {
    .splide__arrow {
      background-color: #e6000a;
      svg {
        fill: #fff;
      }
    }
    .splide__arrow.splide__arrow--prev {
      margin-left: -50px;
    }
    .splide__arrow.splide__arrow--next {
      margin-right: -50px;
    }
  }
}

.hawa-news-kit {
  .splide {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
