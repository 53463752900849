@font-face {
	font-family: "hawafonticons";
	src: url("../Fonts/hawafonticons.eot?#iefix") format("embedded-opentype"),
url("../Fonts/hawafonticons.woff") format("woff"),
url("../Fonts/hawafonticons.woff2") format("woff2"),
url("../Fonts/hawafonticons.ttf") format("truetype"),
url("../Fonts/hawafonticons.svg#hawafonticons") format("svg");
}


.hawafonticon:before {
	font-family: hawafonticons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: text-bottom;
	line-height: 1;
}

.hawa-aesthetics:before {
	content: "\f101";
}
.hawa-arrow-down:before {
	content: "\f102";
}
.hawa-arrow-left:before {
	content: "\f103";
}
.hawa-arrow-right:before {
	content: "\f104";
}
.hawa-calendar-check-regular:before {
	content: "\f105";
}
.hawa-certificated:before {
	content: "\f106";
}
.hawa-chevron-down:before {
	content: "\f107";
}
.hawa-chevron-right:before {
	content: "\f108";
}
.hawa-chevron-up:before {
	content: "\f109";
}
.hawa-comfort:before {
	content: "\f10a";
}
.hawa-creates-space:before {
	content: "\f10b";
}
.hawa-damping:before {
	content: "\f10c";
}
.hawa-efficiency:before {
	content: "\f10d";
}
.hawa-envelope:before {
	content: "\f10e";
}
.hawa-facebook:before {
	content: "\f10f";
}
.hawa-flexibility:before {
	content: "\f110";
}
.hawa-fullscreen:before {
	content: "\f111";
}
.hawa-globe:before {
	content: "\f112";
}
.hawa-height:before {
	content: "\f113";
}
.hawa-home:before {
	content: "\f114";
}
.hawa-idea-note:before {
	content: "\f115";
}
.hawa-innovation:before {
	content: "\f116";
}
.hawa-instagram:before {
	content: "\f117";
}
.hawa-lens-bold:before {
	content: "\f118";
}
.hawa-lens:before {
	content: "\f119";
}
.hawa-linkedin:before {
	content: "\f11a";
}
.hawa-map-marker:before {
	content: "\f11b";
}
.hawa-micon:before {
	content: "\f11c";
}
.hawa-partner:before {
	content: "\f11d";
}
.hawa-phone:before {
	content: "\f11e";
}
.hawa-pinterest:before {
	content: "\f11f";
}
.hawa-play-button:before {
	content: "\f120";
}
.hawa-prevents-draught:before {
	content: "\f121";
}
.hawa-prevents-light-transmission:before {
	content: "\f122";
}
.hawa-productivity:before {
	content: "\f123";
}
.hawa-protects-from-dust:before {
	content: "\f124";
}
.hawa-protects-from-odour:before {
	content: "\f125";
}
.hawa-range:before {
	content: "\f126";
}
.hawa-reduces-noise:before {
	content: "\f127";
}
.hawa-rss:before {
	content: "\f128";
}
.hawa-security:before {
	content: "\f129";
}
.hawa-sound-insulation:before {
	content: "\f12a";
}
.hawa-thickness:before {
	content: "\f12b";
}
.hawa-twitter:before {
	content: "\f12c";
}
.hawa-weight:before {
	content: "\f12d";
}
.hawa-width:before {
	content: "\f12e";
}
.hawa-xing:before {
	content: "\f12f";
}
.hawa-youtube:before {
	content: "\f130";
}
