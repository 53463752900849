.grid-row {
  .mprofi-videostream {
    &.video-js {
      .vjs-big-play-button {
        height: $font-size-base  * 2;
        width: $font-size-base  * 2;
        font-size: $font-size-base * 3;
        margin-top: -$font-size-base  * 3 / 2;
        margin-left: -$font-size-base  * 3 / 2;
      }
    }
  }
}
