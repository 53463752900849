.list-group-search-result {
  border: 0 none transparent;
  list-style-type: none;
  padding: 0;
  margin-top: 2em;

  li {
    padding: $spacer 0;
    border: 0 none transparent;
    border-bottom: $border-width solid $border-color;
  }

  div {
    &.neos-result {
      border-bottom: 1px solid #003c78;
      margin-bottom: 2em;
      padding-bottom: 2em;

      p {
        max-width: 100%;
        color: #003C78;
        line-height: 1.5;
      }

    }
  }

  .readmore-searchresult-link {
    color: #e6000a;
    span {
      &.icon {
        padding-left: 0.5rem;
      }
    }
  }

  .breadcrumb {
    .breadcrumb-link-highlight {
      _color: theme-color("secondary");
    }
    .hawafonticon {
      padding: 0 $spacer * .25;
      font-size: $font-size-base * .75;

      &::before {
        vertical-align: initial;
      }
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 5%;
      border-bottom: 0px solid theme-color('secondary');
      margin-top: $spacer * .625;
      margin-bottom: $spacer * .625;
    }
  }
}

.neos-widget-paginator {
  margin-left: auto;
  display: inline-block;
  list-style-type: none;

  li {
    display: inline-block;
  }
}
