.hawa-navbar {
  .hawa-mega-menu {
    @include media-breakpoint-up(lg) {
      margin-top: auto;
      margin-bottom: .7rem;
    }

    &.mobile-language-switch {
      @include media-breakpoint-up(lg) {
        display: none;
      }

      .hawa-dropdown-menu {
        .container {
          margin-left: 0 !important;
        }
      }

      margin: 20px 0px;

      float: none;
      .dropdown-toggle {
        display: none;
      }
      ul {
        &.navbar-nav {
          float: none;
          width: 100%;
        }
      }
      .dropdown {
        position: relative;
        display: block;
      }
      .dropdown-toggle {
        display: none !important;
      }
      .dropdown-menu {
        display: block !important;
        position: relative !important;
        left: inherit !important;
        top: inherit !important;
        background-color: transparent !important;

        .col-lg-12 {
          padding: 0px;
        }

        // .container {
        //   ;
        // }
      }

      .a-link {
        background: #fff;
        display: block;
        float: left;

        margin-right: 15px;
        margin-left: 0;
        text-align: center;
        line-height: 2.3rem;
        font-size: 16px;
        font-weight: bold;

        padding: 0px 20px;
        height: auto;
        width: auto;

        &.active {
          color: #fff !important;
          background: #e6000a;
        }
      }

    }

    &.nav-desktop {
      .nav-item-search {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &.nav-mobile-only {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
      .nav-item-links {
        a {
          font-weight: normal !important;
        }
      }
    }

    .navbar-nav {
      padding-left: 0;
      
      @include media-breakpoint-up(lg) {
        padding-left: $navbar-padding-x;
      }

      .nav-item {
        .nav-link {
          font-weight: $font-weight-bold;
        }
      }

      @include media-breakpoint-up(lg) {
        border: none;
        margin-left: auto;

        .nav-link {
          &.dropdown-toggle {
            padding-right: $navbar-padding-x - .32;
          }
        }
      }
    }


    .dropdown {
      position: initial;

      @include media-breakpoint-up(lg) {
        &:hover {
          .nav-link {
            background-color: gray("300");
          }
        }
      }
    }

    .languagemenu {
      &.dropdown {
        .hawa-dropdown-menu {
          text-align: left !important;

          a {
            line-height: 1.5em !important;
          }
        }
      }
    }

  }

  .hawa-dropdown-menu {
    background-color: transparent;
    padding: 0;
    margin-top: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0rem;
    font-size: $font-size-base;

    .btn {
      font-size: $font-size-base;
    }

    .row {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      background-color: gray("300");
      padding: $spacer * 2.5 0;

      & > .container-fluid,
      & > .container {
        padding: 0 ($spacer * 2.5 - $grid-gutter-width / 32px);
        margin: 0 $grid-gutter-width / 2;
      }
    }

    .a-text {
      color: theme-color("primary");
    }

    .hawa-section-headline {
      font-weight: $font-weight-bold;
      color: theme-color("primary");
      font-size: $font-size-base;
      padding-top: $spacer * .25;
      padding-top: $spacer * .25;
      margin-top: $spacer * .25;
      margin-bottom: $spacer * .25;
      
      @include media-breakpoint-up(lg) {
        padding: 0;
        margin-top: 0;
        margin-bottom: $spacer * 1.25;
      }
  }

    .hawa-scale-on-hover {
      overflow: hidden;

      img {
        transition: all .5s;
      }
    }

    a {
      &.hawa-navbar-link {
        margin-top: $spacer * .625;
        margin-bottom: $spacer * .625;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-bottom: $spacer * 1.25;
        }
      }


      .hawa-section-headline {
        margin-top: $spacer * .25;
        margin-bottom: $spacer * .25;
        
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-bottom: $spacer * 1.25;
        }

        &::after{
          content: url(../Images/blue-button-right.svg);
          position: relative;
          height: 45px;
          width: 45px;
          margin-left: 5px;
          border: 0;
          transition: all 0.5s ease;
        }
      }

      &:hover {
        .hawa-section-headline {
          color: theme-color("primary")Accent;

          &::after{
            content: url(../Images/red2-button-right.svg);
          }
        }

        .a-text {
          color: theme-color("primary")Accent;
        }

        .hawa-large-teaser-description {
          color: theme-color("primary");
        }

        .hawa-scale-on-hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }


  .hawa-large-teaser {
    // border-top: 1px solid gray("300");
    border-bottom: 1px solid gray("300");

    @include media-breakpoint-up(lg) {
      border: 0 none transparent;
    }

    .hawa-large-teaser-image {
      margin-bottom: $spacer * 1.25;
    }

    .hawa-large-teaser-description {
      margin-bottom: 0;
      max-width: 185px;
      font-size: $font-size-base * .875;
      line-height: $line-height-sm;
    }
  }

  .hawa-teaser {
    margin-bottom: $spacer * .625;

    &:last-child {
      margin-bottom: 0;
    }

    .hawa-teaser-image img {
      width: 48px;
      
      @include media-breakpoint-up(lg) {
        width: 85px;
      }
    }
  }


  .socialbar {
    margin: 0 (-$spacer * 0.3125);

    .list-group-item {
      padding: $spacer * 0.3125;
    }
  }
}

/* topbar-megamenu */

.topbar-megamenu {

  .dropdown-toggle::after {
    padding-top: 0px;
    height: 18px;
  }

  ul {
    float: right;
    display: inline-block;
    &.navbar-nav {
      li {
        display: inline-block;
        position: relative;
        padding-right: 10px;

        a {
          font-size: .75rem;
          padding-top: 5px;
          white-space: nowrap;
        }

        &.dropdown {
          .dropdown-menu {
            float: none;
            position: absolute;
            right: auto;
            left: -25px;
            top: 33px;
            border: 0;
            background-color: #e9e6eb;
            border-radius: 0;
          }
        }
      }
    }
  }
}

