.news-list-container-xl {
  @include media-breakpoint-down(xs) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.m-filteredList {
  @include media-breakpoint-down(md) {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  @include media-breakpoint-down(xs) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.news-list-item {

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px !important;
  }

  .m-newsList {
    &.m-filteredList {
      .container-xl {
        @include media-breakpoint-down(xs) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }




    }
  }
  .event-details {
    position: relative;
    overflow: hidden;
    .left,
    .right {
      float: left;
    }
    .left {
      width: 25%;
    }
    .right {
      width: 65%;
    }
  }

  .event-label {
    color: #fff;
    background-color: #003c78;
    z-index: 10;
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 5px 50px 5px 40px;
    .hawafonticon {
      margin-left: 10px;
      top: 4px;
      position: absolute;
      right: 10px;
    }
    .hawafonticon.webinar {
      margin-left: 10px;
      top: 12px;
      position: absolute;
      right: 10px;
      height: 13px;
      content: url("/_Resources/Static/Packages/Hawa.Master/Images/icon_webinar.png");
    }
  }

  margin-bottom: 70px;

  .news-list-item-inner {

    background-color: #e9eaeb;

    .newsList__itemTextBlock {

      h3 {
        color: #003c78;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }

      .news-divider {
        background-color: #e30a13;
        width: 40px;
        height: 4px;
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .news-teaser-tags {
        color: #003c78;
        font-size: 16px;
      }

      padding: 13px;
      min-height: 115px;
    }

  }
}

.teaser-image-outer {
  width: 100%;
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.teaser-image-inner {
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  background-size: cover;
  transition: .5s all;
}

.teaser-image-inner:hover {
  transform:scale(1.1);
}

a {
  .m-newsList__itemDateTime {
    color: #089cc8;
    line-height: 2.0em;
    font-weight: normal;
  }

  &:hover {
    .news-teaser-text {
      color: #003c78;
    }
  }
}
