.m-feature {
    display: block;
    height: 100%;
    padding: 2rem 2rem 0 0;

    /* Reset default link styling */
    &,
    &:hover {
        text-decoration: none !important;
        color: initial;
    }

    &:hover {
        .m-feature__icon .a-icon-frame:last-child {
            opacity: 1;
        }
    }


    &.m-feature--bulletlist {
      padding-top: 0px;
      padding-bottom: 5px;
      img {
        max-height: inherit;
        max-width: 18px;
        width: 18px;
      }
      p {
        color: theme-color("primary");
      }
    }

    &__icon {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(gutter(md));
        color: $white;

        .a-icon-frame:last-child {
            transition: opacity 0.3s;
            opacity: 0;
        }
    }

    &__image {
      .a-image {
        text-align: center;
        img {
          height: 2.3em;
        }
      }
    }

    .text {
      p {
        font-size: inherit;
      }
    }
}


.container--colored {
  &.container--color-primary {
    .m-feature {
      div,
      div span,
      p,
      h2,
      h3,
      h4 {
        color: $white;
      }
    }

    .o-list-feature__header {
      p,
      h2,
      h3,
      h4 {
        color: $white;
      }
    }
  }
}
