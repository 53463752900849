.icon {
  &.icon--left {
    padding-right: 2rem;
  }

  &.icon--right {
    padding-left: 2rem;
  }
}

.link-animated {
  .icon-animated {
    &.icon-animated--left::before,
    &.icon-animated--right::before,
    &.icon-animated--up::before,
    &.icon-animated--down::before {
      display: inline-block;
      transition: transform 0.5s ease-out 0s;
    }
  }

  &:hover {
    .icon-animated {
      &.icon-animated--left::before {
        transform: translateX(-$spacer * .33);
      }
      &.icon-animated--right::before {
        transform: translateX($spacer * .33);
      }
      &.icon-animated--up::before {
        transform: translateY(-$spacer * .33);
      }
      &.icon-animated--down::before {
        transform: translateY($spacer * .25);
      }
    }
  }
}
