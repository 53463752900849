:root {
  --aa-text-color-rgb: 0,60,120;
  --aa-primary-color-rgb: 0,60,120;
  --aa-panel-border-color-rgb: 0,60,120;
  --aa-input-background-color-rgb: 0,60,120;
  --aa-input-background-color-alpha: 1;

}

.live-search-item {
  line-height: 1.2;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  &__title {
    color: #003C78;
    line-height: 1.2;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &__description {
    font-size: 0.9em;
  }

  &__image {
    width: 100px;
    float: right;
    margin-left: 10px;
    order: 2;
  }

  &__content {
    order: 1;
  }

}


.aa-Panel {
  z-index: 2000 ;
  _display: none;
}

#autocompletePanel {
  padding-top: 1em;
  .aa-Panel {
    z-index: 2000;
    top: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    position: relative !important;
    box-shadow: none;
    .aa-PanelLayout {
      height: auto !important;
      max-height: inherit !important;
      padding: 0px !important;
      .aa-PanelSection--full {
        padding: 0px !important;;
      }
    }

  }

  .live-search-item--site-searchword {
    .live-search-item__content {
      width: 100%;
    }
  }

  .aa-PanelSection--searchwords {
    li.aa-Item {
      background-color: transparent !important;
      span.aa-ItemLink {
        padding-left: 0px;
        margin-bottom: 3px;
        color: #999;
        margin-top: 3px;
        @include media-breakpoint-down(md) {
          padding-left: 0px;
        }
      }
    }
  }

  .aa-PanelSection--top {
    h4 {
      padding-left: 0px;
      @include media-breakpoint-down(md) {
        padding-left: 0px;
      }
    }
    .live-search-item__content {
      width: 100%;
    }
    li.aa-Item {
      background-color: transparent !important;
      .live-search-product__link {
        a {
          font-size: .8em;
          margin-top: 10px;
          display: block;
        }
      }
      .live-search-item__dividor {
        border-bottom: 1px solid #003C78;
        padding-bottom: 1.9em;
        margin-bottom: 1em;
        margin-left: 0px;
        @include media-breakpoint-down(md) {
          margin-left: 0px;
        }
      }

      a.aa-ItemLink {
        padding-left: 0px;
        &.aa-ItemLink-text {
          color: #003C78;
        }
        @include media-breakpoint-down(md) {
          padding-left: 0px;
        }
      }
      div.live-search-product__link {
        a {
          padding-left: 0px;
          @include media-breakpoint-down(md) {
            padding-left: 0px;
          }
        }

      }
      &:hover {
        a {
          color: #e6000a;
        }
      }
    }
  }

  .aa-PanelSection--searchwords {
    li.aa-Item {
      height: auto;
      padding: 0px;
      min-height: auto;
      a {
        padding: 8px 0px;
        padding-left: 0px;
        @include media-breakpoint-down(md) {
          padding-left: 0px;
        }
      }
    }
  }
}

.aa-PanelSections {
  display: flex;
  flex-wrap: wrap;

}

.aa-PanelSection {
  padding: 10px;
  &--left {
    width: 30%;

    padding: 0 10px;
  }
  &--right {
    width: 70%;
    padding: 0 10px;
  }
  &--full {
    width: 100%;
    padding: 0 10px;
  }
}
.aa-Form:focus-within {
  box-shadow: none !important;
  border-color: $primary !important;
}

.form-control:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: none;
  color: #495057;
  outline: 0;
}

.live-results {
  .search-hit-count {
    font-size: 0.875rem;
  }
}
.aa-SourceNoResults {
  padding: 0px 0px !important;
}

.liveSearchHeaderBox {
  .noresultmsg {

  }
  .noresultmsg-template {
    display: none;
  }
}


.noresultmsg {
  line-height: 1.2em;
}

#autocomplete {
  .aa-Form {
    border: 1px solid #e5e5e5;
    border-radius: 0;

    &:focus-within {
      box-shadow: none;
      border-color: #80bdff;
    }
  }
}

// mobile
.autocomplete-element {
  .aa-DetachedSearchButton {


    .aa-DetachedSearchButtonIcon {
      padding: 0 20px;
      position: absolute;
      right: 0px;
      background-color: #003c78;
      .aa-Label svg, .aa-LoadingIndicator svg {
        color: #ffffff;
        scale: 1.2;
      }

      svg {
        &.aa-SubmitIcon {
          display: none;
        }
      }
    }

    .aa-DetachedSearchButtonIcon::before {
      content: "\f118";
      font-family: hawafonticons !important;
      font-style: normal;
      font-weight: normal !important;
      vertical-align: text-bottom;
      line-height: 1;
      color: #fff;
    }

  }
}


.autocomplete-element {
  .aa-Autocomplete {

    .aa-InputWrapperSuffix {
      display: none;
    }

    .aa-SubmitButton {
      @include media-breakpoint-down(md) {
        padding-left: 10px;
      }
    }

    .aa-LoadingIndicator {
      @include media-breakpoint-down(md) {
        padding-left: 10px;
      }
    }

    .aa-SubmitButton::before {
      content: "\f118";
      font-family: hawafonticons !important;
      font-style: normal;
      font-weight: normal !important;
      vertical-align: text-bottom;
      line-height: 1;
      color: #fff;
    }

    .aa-InputWrapperPrefix {
      align-items: flex-start;
      order: 4;
      background-color: #003c78;
      .aa-Label {
        margin-bottom: 0px;
      }
      .aa-Label svg, .aa-LoadingIndicator svg {
        color: #ffffff;
        scale: 1.2;
      }

      svg.aa-SubmitIcon {
        display:none;
      }
    }
    .aa-InputWrapper {
      input {
        padding-left: 16px;
      }
    }
    .aa-Form {
      border-radius: 0px;
    }

  }
}
