.btn-call-to-action {
  background-color: $gray-100;
  
  .hawafonticon::before {
      vertical-align: baseline;
      font-weight: $font-weight-bolder !important;
      color: $primary;
  }

  &:hover {
    background-color: $primary;

    .hawafonticon::before {
      color: $white;
    }

    * {
      color: $white;
    }
  }
}
