.entityHeader {
  .entityHeader-subheader {
    color: #089cc8;
    font-size: 1.25rem;
    font-weight: 700;
  }
  h1 {
    margin-top: 0;
  }
}


.productfinder-links a {
  width: 100%;
  text-align: left;
  .icon {
    float: right;
  }
}

button.activeFilter {
  border: 1px solid red;
}

.filterContainer {

  .vs__dropdown-toggle {
    border-radius: 0;
    background-color: #e5e0e0;
    border: 1px solid #dbdbdb;
    color: #003C78;

    .vs__selected {
      color: #003C78 !important;
    }

    .vs__search, .vs__search:focus {
      margin: 7px 0 0;
    }
  }

  .vs__clear,
  .vs__open-indicator {
    fill: #003C78;
  }

  .vs__open-indicator {
    margin-top: 4px;
  }

  .vs--searchable {
    .vs__dropdown-toggle {
      cursor: pointer;
      .vs__search {
        cursor: pointer;
        color: #003C78;
      }
    }
  }

  .filterLabel {
    color: #003c78;
  }

  button {
    .hawafonticon {
      margin-left: 10px;
    }
  }
}

// kleinere abstände im raster
.m-referenceList {
  .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    &.project-list-item {
      padding-left: .9375rem;
      padding-right: .9375rem;
    }
  }
  .row {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

#app-filter-reference {
  .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
      padding-left: .9375rem;
      padding-right: .9375rem;
  }
  .row {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

.infobox-related-entity {
  .hawa-arrow-right:before {
    line-height: 1.3;
  }
}
