.news-filter-toggle {
  background-color: #f4f4f4;
  padding: 10px 25px 10px 25px;
  color: #003C78;
  border-top: 1px solid #e9eaeb;

  margin-left: -25px;
  margin-right: -25px;

  @include media-breakpoint-down(lg) {
    .filterContainer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    line-height: 1.7;
  }

  .btn-reset:hover {
    background-color: transparent !important;
    border-color: #003c78 !important;
    color: #003c78 !important;
  }
  .btn-reset:active {
    background-color: transparent !important;
    border-color: #003c78 !important;
    color: #003c78 !important;
  }

  .filter-dropdown {
    .vs__dropdown-toggle {
      background-color: transparent !important;
      border-color: #003c78 !important;
      color: #003c78 !important;
      border-width: 2px;
    }
  }

  .filterLabel {
    font-size: 1.125rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.nice-select-down {
  display: block;
  position: relative;
  top: -3px;
}

.nice-select-down::after {
  border-bottom: 1px solid #003C78;
  border-right: 1px solid #003C78;
  content: '';
  height: 8px;
  margin-top: -15px;
  pointer-events: none;
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
  display: inline-block;

}
