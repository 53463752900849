.custom-file .custom-file-label {
  background: transparent;
  border: none;
  position: absolute;
  margin-bottom: 0;
  padding-bottom: 0;
  top: 0px;
  line-height: 1.7em;
  font-size: .6875rem;
  &::after {
    display: none;
  }
}

.custom-file-input {
  opacity: 1;
  color: $blue;
  z-index: 1;
  border: 1px solid $gray-400;
  border-radius: 0;
  font-weight: 400;
  overflow: hidden;
  padding: 1.875em .75rem .375rem;
  height: auto;
}

.custom-file-input::file-selector-button {
  ;
}
