.page-navigation {
  margin: 0em 0px 60px;

  .neos-widget-paginator,
  .pagination {
    list-style-type: none;
    float: right;

    &.container {
      float: none;
      text-align: right;
    }

    .previous,
    .next,
    li {
      display: inline-block;
      position: relative;

      color: #003C78;
      background-color: transparent;
      margin-left: 10px;
      font-size: 1.25rem;

      a {
        display: block;
        border: 0px solid #003C78;
        font-size: 1.25rem;
        padding: 0 1.25rem;
      }

      a:hover {
        color: #e6000a;
      }
    }

    li.pagenumber {
      top: -14px;
    }

    li.current {
      _background-color: #e6000a;
      color: #e6000a;
    }

    .doubleLeft a {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTAuNywyMC4xIDIxLjIsOS41IDIyLjcsMTEgMTMuNywyMC4xIDIyLjcsMjkgMjEuMiwzMC41IAkiLz4NCgk8Zz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNy4zLDIwLjEgMjcuNyw5LjUgMjkuMywxMSAyMC4yLDIwLjEgMjkuMywyOSAyNy43LDMwLjUgCQkiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzksMXYzOEgxVjFIMzkgTTQwLDBIMHY0MGg0MFYwTDQwLDB6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      padding: 0px;
    }

    .singleLeft {
      margin-left: 0px;
    }

    .singleLeft a {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCjwvZz4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTQsMjAuMSAyNC41LDkuNSAyNiwxMSAxNi45LDIwLjEgMjYsMjkgMjQuNSwzMC41ICIvPg0KPC9zdmc+DQo=");
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      padding: 0px;
    }

    .doubleRight a {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCgk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjI5LjMsMTkuOSAxOC44LDMwLjUgMTcuMywyOSAyNi4zLDE5LjkgMTcuMywxMSAxOC44LDkuNSAJIi8+DQoJPGc+DQoJCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjIuNywxOS45IDEyLjMsMzAuNSAxMC43LDI5IDE5LjgsMTkuOSAxMC43LDExIDEyLjMsOS41IAkJIi8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=");
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      padding: 0px;
    }

    .singleRight a {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCjwvZz4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjYsMTkuOSAxNS41LDMwLjUgMTQsMjkgMjMuMSwxOS45IDE0LDExIDE1LjUsOS41ICIvPg0KPC9zdmc+DQo=");
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      padding: 0px;
    }

    .singleRight {
      margin-right: 0px;
    }

    .doubleRight {
      margin-left: 0px;
    }
  }
}
