#reference-archive-filter {
  background-color: #f4f4f4;
  color: #003C78;
  border-top: 1px solid #e9eaeb;
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.reference-archive-filter-toggle {
  background-color: #f4f4f4;
  color: #003C78;
  border-top: 1px solid #e9eaeb;
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
  }

  .btn-reset:hover {
    background-color: transparent !important;
    border-color: #003c78 !important;
    color: #003c78 !important;
  }
  .btn-reset:active {
    background-color: transparent !important;
    border-color: #003c78 !important;
    color: #003c78 !important;
  }

  .filter-dropdown {
    .vs__dropdown-toggle {
      background-color: transparent !important;
      border-color: #003c78 !important;
      color: #003c78 !important;
      border-width: 2px;
    }
  }

  .filterLabel {
    font-size: 1.125rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.nice-select-down {
  display: block;
  position: relative;
  top: -3px;
}

.nice-select-down::after {
  border-bottom: 1px solid #003C78;
  border-right: 1px solid #003C78;
  content: '';
  height: 8px;
  margin-top: -15px;
  pointer-events: none;
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
  display: inline-block;
}



#reference-archive-list .v-select,
#reference-archive-list .nice-select  {
  border:1px solid #dbdbdb !important;
  background-color: #e5e0e0 !important;
  margin: 0 0 12px 0 !important;
}

#reference-archive-list .nice-select li {
  padding-left: 8px !important;

}

#reference-archive-list .nice-select .current {
  padding-left: 0px;
}

#reference-archive-list input[type="text"] {
  padding-left: 8px;
  height: 44px;
  font-size: 1rem !important;
  color: #003C78 !important;
  width: 100% !important;
  margin: 0 0 12px 0 !important;
  border: 1px solid #dbdbdb !important;
  background-color: #e5e0e0;
}

#reference-archive-list .filter-label {
  padding-bottom: 8px;
  line-height: 40px;
  font-weight: 700;
  color: #003c78;
  font-size: 17px;
  white-space: nowrap;
}

#reference-archive-list .check-entry {
  line-height: 25px;
  color: #003c78;
  font-size: 17px;
  white-space: nowrap;
}
div.border-bottom {
  border-bottom: 0;
}
#reference-archive-list input[type="checkbox"] {
  margin-top: 4px;
}

#reference-archive-list-list .no-results {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 700;
  background-color: #e9eaeb;
  color: #003C78;
  padding: 10px;
}

#reference-archive-list .reset-filter-button {
  border: solid 2px #e9eaeb;
  background-color: white;
  color: #003C78 !important;
  width: 100%;
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;
  text-align: left;
  /*padding-left: 19px;*/
  padding-left: 8px;
  height: 44px;
  line-height: 40px;
}

#reference-archive-list .reset-filter-button:HOVER {
  background-color: #e9eaeb;
}
#reference-archive-list .reset-filter-button span {
  position: absolute;
  right: 33px;
  font-weight: 700;
}

.refarchive-filter {
  background-color: #f4f4f4;
  padding: 35px;

}

#reference-archive-list .search-button {
  width: 100%;
  cursor: pointer;
}

#reference-archive-list .search-button span {
  padding-top: .3em;
}

/* pagination container */
#reference-archive-list .pagination {
  list-style-type: none;
  float: right;
}

/* first an last button if on first or last page */
.invisiblepagebutton {
  display: none !important;
}

/* left / right buttons */
.page-navi-button {
  cursor: pointer;
  font-size: 1.2em;
  line-height: 42px;

}

.page-navi-end-button-right {
  border-left: none;
}
.page-navi-end-button-left {
  border-right: none;
}

/* buttons containing the page number */



/* the LI pagination items j*/
#reference-archive-list .page-item {
  display: inline-block;
  position: relative;

}

/* Table */
#reference-archive-list-list table {
  width: 100%;
  border-spacing: 3px;
  margin-top: 45px;
}

#reference-archive-list-list th, td {
  text-align: left;
  padding: 4px 8px;
}

#reference-archive-list-list th {
  color: #003C78;
}

#reference-archive-list-list tr {
  background-color: #f4f4f4;
}
#reference-archive-list-list tr:nth-child(odd) {
  background-color: #e9eaeb;
}
#reference-archive-list-list tr:nth-child(1) {
  _background-color: transparent;
}

#reference-archive-list-list td {
  height: 42px;
  border: 3px solid #fff;
}

.page-number-item {
  width: 75px;
}

.column-label {
  font-weight: 700;
  color: #003C78;
}
@media (max-width: 900px) {

  .min-view {
    width: 100%;
  }
  .max-view {
    display: none;
  }
}
@media (min-width: 901px) {

  .min-view {
    display: none;
  }
  .max-view {
  }
}



/* pagination container */
#reference-archive-list .pagination {
  list-style-type: none;
  float: right;
}

/* first an last button if on first or last page */
.invisiblepagebutton {
  display: none !important;
}

/* left / right buttons */
.page-navi-button {
  cursor: pointer;
  font-size: 1.2em;
  line-height: 42px;

}

.page-navi-end-button-right {
  border-left: none;
}
.page-navi-end-button-left {
  border-right: none;
}
/* selected page */
button.activepage {
  color: #e6000a;
}

/* the LI pagination items j*/
#reference-archive-list .page-item {

}

.smart-pagination {
  ul {
    list-style-type: none;
    float: right;
    list-style-position: inside;
    margin-bottom: 1em;
    margin-top: 1em;
    line-height: 1.5em;

    /* selected page */
    li.page-item.active {
      a {
        color: #e6000a;
      }
    }


    li {
      display: inline-block;
      position: relative;

      a {
        border: 0 solid #003c78;
        display: block;
        font-size: 1.25rem;
        padding: 0 1.25rem;
        line-height: 1.7em;
      }
    }
  }

  .doubleLeft {
    display: block;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTAuNywyMC4xIDIxLjIsOS41IDIyLjcsMTEgMTMuNywyMC4xIDIyLjcsMjkgMjEuMiwzMC41IAkiLz4NCgk8Zz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNy4zLDIwLjEgMjcuNyw5LjUgMjkuMywxMSAyMC4yLDIwLjEgMjkuMywyOSAyNy43LDMwLjUgCQkiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzksMXYzOEgxVjFIMzkgTTQwLDBIMHY0MGg0MFYwTDQwLDB6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    padding: 0px;
    position: absolute;
    top: 14px;
  }

  .singleLeft {
    margin-left: 0px;
  }

  .singleLeft {
    display: block;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCjwvZz4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTQsMjAuMSAyNC41LDkuNSAyNiwxMSAxNi45LDIwLjEgMjYsMjkgMjQuNSwzMC41ICIvPg0KPC9zdmc+DQo=");
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    padding: 0px;
    position: absolute;
    top: 14px;
  }

  .doubleRight {
    display: block;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCgk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjI5LjMsMTkuOSAxOC44LDMwLjUgMTcuMywyOSAyNi4zLDE5LjkgMTcuMywxMSAxOC44LDkuNSAJIi8+DQoJPGc+DQoJCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjIuNywxOS45IDEyLjMsMzAuNSAxMC43LDI5IDE5LjgsMTkuOSAxMC43LDExIDEyLjMsOS41IAkJIi8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=");
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    padding: 0px;
    position: absolute;
    top: 14px;
  }

  .singleRight {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMwMDNDNzg7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOSwxdjM4SDFWMUgzOSBNNDAsMEgwdjQwaDQwVjBMNDAsMHoiLz4NCjwvZz4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjYsMTkuOSAxNS41LDMwLjUgMTQsMjkgMjMuMSwxOS45IDE0LDExIDE1LjUsOS41ICIvPg0KPC9zdmc+DQo=");
    display: block;
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    padding: 0px;
    position: absolute;
    top: 14px;
  }

  .singleRight {
    margin-right: 0px;
  }

  .doubleRight {
    margin-left: 0px;
  }

  [aria-label="Previous"] {
    padding: 0px;
    display: block;
    width: 44px;
    height: 44px;
    position: relative;
  }
  [aria-label="Next"] {
    padding: 0px;
    display: block;
    width: 44px;
    height: 44px;
    position: relative;
  }
}
