@mixin caret-down() {
  border: 0;
  content: url(../Images/icon_arrow_meta_drop-down.svg);
  height: 20px;
  width: 20px;
  vertical-align: text-bottom;
  margin-left: 0;
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      @if $direction == down {
        @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == end {
        @include caret-end();
      }
    }
  }
}
