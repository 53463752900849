.m-filteredList {
  margin-bottom: 1em;
}

.m-filteredList {

  .directions-link {
    font-size: 13px;
  }

  .m-vendorList__itemTitle {
    color: #1c2368;
    font-weight: bold;
    font-size: 18px;
  }
  .vendor-item-header-sourcetype {
    color: #848484;
    font-size: 18px;
    height: 30px;
  }

  .vendor-list-item-inner {
    position: relative;
  }

  .vendor-item-header-toggler {
    font-size: 36px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    padding: 0 10px;
    color: #1c2368;

    .plus {
      display: block;
    }
    .minus {
      display: none;
    }

    &.open {
      padding: 0 14px;
      .plus {
        display: none;
      }
      .minus {
        display: block;
      }
    }
  }

  .vendor-item-body {
    display: none;
  }
}

.checkboxlist-container {
  label {
    padding-right: 16px;
    cursor: pointer;
  }

  label::selection, label *::selection {
    background-color: Transparent;
  }
}
