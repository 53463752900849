.page-slide-3 {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $black;
  }

  .btn-primary {
    background-color: $black;
    border-color: $black;
    color: #fff;
  }
}
