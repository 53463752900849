.landingpage--slide {

  &.landingpage--slide--variant--blue {
    .slide--content-container {
      .slide--text-bg {
        background-color: color("blue");
        mix-blend-mode: unset;
        opacity: 0.9;
      }
    }
  }

  &.landingpage--slide--variant--lightblue {
    .slide--content-container {
      h1 {
        color: #fff !important;
      }
      .slide--text-bg {
        background-color: #82c7e4 !important;
        mix-blend-mode: unset;
        opacity: 0.9;
      }
    }
  }

  &.landingpage--slide--variant--ocker {
    .slide--content-container {
      h1 {
        color: #fff !important;
      }
      .slide--text-bg {
        background-color: #eecd5d !important;
        mix-blend-mode: unset;
        opacity: 0.9;
      }
    }
  }

  width: 100%;
  min-height: 450px;
  overflow: hidden;

  .container-fluid {
    max-width: 1260px;
    margin: 0 auto;
    h1 {
      margin: 2.5rem 0 0;
      padding: 0;
    }
  }
  .slide--big-image-container {
    margin-top: -1rem;
    svg {
      display: block;
      width: 0;
      height: 0;
    }
    .slide--big-image {
      img {
        display: block;
        width: 100%;
      }
      &:before {
        width: 0;
        height: 0;
        border-right: 100vw solid transparent;
        border-left: 0 solid transparent;
        border-top: 7vh solid #fff;
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        left: 0;
        z-index: 10;
      }
      &:after {
        width: 0;
        height: 0;
        border-left: 100vw solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 5vh solid #fff;
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
      }
      &::before {
        width: 0;
        height: 0;
        border-right: 100vw solid transparent;
        border-left: 0 solid transparent;
        border-top: 7vh solid #fff;
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }
  }
  .slide--content-container {
    .slide--content {
      h2 {
        font-size: 40px;
        margin-bottom: 1em;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
      }
      position: relative;
      z-index: 2;
      color: #fff;
      padding: 0 1.875rem;
      max-width: 1260px;
      margin: 0 auto;
      .h2 {
        font-size: 40px;
        margin-bottom: 1em;
      }
      p {
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 2.5rem;
      }
    }
    margin-top: -12rem;
    margin-bottom: 8rem;
    padding-top: 2.5rem;
    padding-bottom: 4.5rem;
    .slide--text-bg {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #003C78;
      z-index: 1;
      transform: skewY(4.5deg);
      mix-blend-mode: multiply;
      opacity: .9;
      transition: all .4s ease-in-out;
    }
  }
  h2 {
    color: #fff;
  }
  h3 {
    color: #fff;
  }
  h4 {
    color: #fff;
  }
  h5 {
    color: #fff;
  }
  h6 {
    color: #fff;
  }
  .slide--content-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
  }
}
.slide--big-image {
  position: relative;
}
.slide--big-image-container {
  overflow: hidden;
}
.hawa-slide {
  h2 {
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
  }
}
@media (max-width:60rem) {
  .landingpage--slide {
    .slide--big-image-container {
      .slide--big-image {
        &:before {
          border-top: 3vh solid #fff;
        }
        &:after {
          border-bottom: 2vh solid #fff;
        }
      }
      margin-top: 0;
    }
    .container-fluid {
      h1 {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }
    .slide--content-container {
      margin-top: -8rem;
      margin-bottom: 3.5rem;
      padding: 3rem 1.875rem;
      .slide--content {
        padding: 0;
        .slide--content-image {
          padding: 0;
          margin-top: 1.5rem;
        }
        .slide--content-text {
          padding: 0;
          h2 {
            font-size: 1.75rem;
            padding-bottom: 1rem;
          }
          .btn {
            min-width: auto;
            max-width: 280px;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width:66rem) {
  .landingpage--slide {
    .slide--big-image-container {
      .slide--big-image {
        &:after {
          border-bottom: 4vh solid #fff;
        }
      }
    }
  }
}
@media (max-width:32rem) {
  .landingpage--slide {
    .slide--big-image-container {
      .slide--big-image {
        &:before {
          border-top: 1vh solid #fff;
        }
        &:after {
          border-bottom: 1vh solid #fff;
        }
      }
    }
    .slide--content-container {
      padding-top: .5rem;
      margin-top: -5rem;
    }
  }
}
