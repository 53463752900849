.footer-nav {
  margin-left: -$spacer * 0.625;
  margin-right: -$spacer * 0.625;

  .nav {
    justify-content: center;
    flex-direction: column;
  }

  .nav-link {
    padding: $spacer * 0.625 $spacer * .5;
  }


  @include media-breakpoint-up(lg) {
    .nav {
      justify-content: flex-start;
      flex-direction: row;
    }

    .nav-link {
      padding: 0 $spacer * .5;
    }
  }
}

.footer--base {
  a {
    color: color('white');

    &:focus, &:hover {
      color: $link-hover-color;
    }
  }

  .footer-language-nav {
    .hawafonticon {
      &::before  {
        font-size: 1.5rem;
        vertical-align: middle;
          color: color('white');
      }
    }
    
    .nav-item {
      border-left: 1px color('white') solid;
      margin: auto 0;
  
      &:first-child {
        border-left: none;
      }
    }

    .nav-link {
      padding: 0 $spacer * .5;
    }
  }
}


@include media-breakpoint-up(lg) {
  .footer--base {
    text-align: left;

    a {
      color: theme-color('primary');

      &:focus, &:hover {
        color: $link-hover-color;
      }
    }

    .footer-nav {
      .nav {
        flex-direction: row;
      }
    }
  
    .footer-language-nav {
      .hawafonticon {
        &::before  {
          color: theme-color('primary');
        }
      }

      .nav-item {
        border-left: 1px theme-color('primary') solid;
      }
    }
  }
}
