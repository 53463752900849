.o-text-image {
    $this: &;

    .row {
      padding-left: 15px;
      padding-right: 15px;
    }

    .container {
        @include media-breakpoint-up(lg) {

        }
    }

    .m-section {
        float: right;
        width: 100%;
        _max-width: rem(600px);
    }


  &--background {
      // font-weight: $font-weight--regular;
      // color: $color-gray--darker;
      background-color:gray("100");
  }

  &__image {
      overflow: hidden;
      text-align: center;

      @include media-breakpoint-up(md) {
          margin-left: 0;
      }

      @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-left: 0;
      }

      &--shape {
          position: relative;
      }

      .a-image {
          position: relative;
          z-index: 10;

          .img-fluid {
            width: 100%;
          }
          img {
            transition: all 500ms;
          }
      }
  }

  &__overlay {
      position: relative;
      z-index: 20;

      @include media-breakpoint-up(lg) {
          position: absolute;
          z-index: 20;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          min-width: rem(280px);
          min-height: rem(100px);
          margin: auto;

          .neos-contentcollection {
              flex: auto;
          }
      }
  }

  &__text {
      width: 100%;
      text-align: left;

      @include media-breakpoint-up(lg) {
          text-align: left;
      }
  }


  .pl-5 {
    padding-left: 0 !important;
  }

  @include media-breakpoint-down(md) {
    .pr-5 {
      padding-right: 0 !important;
    }
  }

  &--left {
      #{$this}__text {
          order: 2;
          margin-top: rem(gutter(xl));
          text-align: left;

          @include media-breakpoint-up(lg) {
              margin-top: 0;
              .pl-5 {
                padding-right: 0 !important;
                padding-left: 3rem !important;
              }
          }

      }

      #{$this}__overlay {
          right: rem(gutter(md));
      }

      .m-section {
          float: left;
          _max-width: rem(600px);
      }

      #{$this}__image {
          order: 1;
          margin-top: 0;
          margin-left: 0;

        /*
          @include media-breakpoint-up(md) {
              margin-right: rem(50px);
          }

          @include media-breakpoint-up(lg) {
              margin-right: rem(100px);
          }

         */
      }
  }
}

@include media-breakpoint-down(md) {
  .o-text-image {
    .m-section__footer {
      margin-bottom: 1rem !important;

      .a-btn {
        display: block;
      }
    }
  }
}

.transition--image--zoom {
  .o-text-image__image {
    img {
      transform: scale(1.05);
      transition: all 500ms;
    }
  }
}

