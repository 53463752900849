.list-with-icon {
  .list-group-item {
    &.hawafonticon {
      &::before {
        position: absolute;
        font-size: $font-size-base * 1.875;
        left: 0;
        color: theme-color("primary");
      }
    }
  }
}

.bg-primary {
  .list-with-icon {
    .list-group-item {
      &.hawafonticon {
        &::before {
          color: $white;
        }
      }
    }
  }
}
