.klaro {
  font-family:inherit;
  font-size:14px;
}
.klaro.we_cookie_consent {
  display:none;
}
.klaro.we_cookie_consent button:focus {
  outline-style:none;
  outline-width:0
}
.klaro.we_cookie_consent.notice--center,
.klaro.we_cookie_consent.notice--center-floated {
  height:0;
  overflow:visible;
  position:fixed;
  top:0;
  width:100%;
  z-index:10000
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) {
  background:#FFF;
  border-radius:0;
  bottom:0;
  -webkit-box-shadow:0 4px 6px 0 rgba(0,0,0,0.2),5px 5px 10px 0 rgba(0,0,0,0.19);
  box-shadow:0 4px 6px 0 rgba(0,0,0,0.2),5px 5px 10px 0 rgba(0,0,0,0.19);
  max-height:100vh;
  max-width:100%;
  overflow-y:auto;
  padding:20px 30px;
  right:0;
  z-index:9999
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body {
  background:#FFF;
  bottom:0;
  left:0;
  margin:auto;
  max-width:100%;
  overflow:hidden;
  padding:0;
  position:relative;
  right:0;
  top:auto;
  width:100%
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin:.5em 0;
  max-width: 100%;
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  padding-top:.5em;
  width:100%
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok>* {
  -webkit-box-flex:0;
  -ms-flex:0 0 100%;
  flex:0 0 100%
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1;
  width:100%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap
}
@media screen and (max-width:384px) {
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width:inherit
  }
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn,
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn-sm {
  padding:0.5em
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-link {
  -webkit-box-ordinal-group:3;
  -ms-flex-order:2;
  order:2;
  color:#161f57;
  font-weight:normal;
  text-align:center;
  text-decoration:none
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-link:hover {
  color:#161f57
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn {
  background:#EFEFEF;
  border-radius:0;
  -webkit-box-shadow:none;
  box-shadow:none;
  color:#161f57;
  margin:.5em 0;
  min-height:40px;
  opacity:1;
  padding:.5rem;
  text-align:center;
  width:100%
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn:hover {
  opacity:.7
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn.cm-btn-success {
  background:#00AFCB;
  color:#FFF;
  -webkit-box-ordinal-group:0;
  -ms-flex-order:-1;
  order:-1
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn.cm-btn-info {
  color:#00AFCB;
  display:block;
  font-weight:normal;
  background:transparent;
  -webkit-box-ordinal-group:4;
  -ms-flex-order:3;
  order:3;
  padding:1em 0;
  text-align:center;
  width:100%
}
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-link,
.klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-learn-more {
  color:#00AFCB;
  display:block;
  font-weight:normal;
  background:transparent;
  -webkit-box-ordinal-group:4;
  -ms-flex-order:3;
  order:3;
  padding:1em 0;
  text-align:center;
  width:100%
}
@media screen and (min-width:768px) {
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok>* {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    min-width:auto
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cn-buttons {
    max-width:75%;
    width:100%
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn {
    background:#EFEFEF;
    margin:.5em 0;
    padding:1em 0;
    width:25%
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn.cm-btn-success {
    background:#00AFCB;
    margin:.5em 20px .5em 0;
    text-align:center;
    width:calc(50% - 40px);
    -webkit-box-ordinal-group:3;
    -ms-flex-order:2;
    order:2
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-btn.cm-btn-info {
    text-align:right
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cn-decline {
    border-color:transparent;
    background:transparent;
    text-align:left
  }
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-link,
  .klaro.we_cookie_consent .cookie-notice:not(.cookie-modal-notice) .cm-learn-more {
    padding:1.5em 0;
    margin:0 0 0 auto;
    width:auto
  }
}
.klaro.we_cookie_consent .cookie-notice .cm-btn.cm-btn-success {
  background:#00AFCB;
  color:#FFF;
  -webkit-box-ordinal-group:0;
  -ms-flex-order:-1;
  order:-1
}
.klaro.we_cookie_consent .cookie-notice .cm-btn.cm-btn-info {
  color:#00AFCB;
  display:block;
  font-weight:normal;
  background:transparent;
  -webkit-box-ordinal-group:4;
  -ms-flex-order:3;
  order:3;
  padding:1em 0;
  text-align:center;
  width:100%
}
.klaro.we_cookie_consent.notice--top .cookie-notice:not(.cookie-modal-notice) {
  bottom:auto;
  top:0
}
@media screen and (min-width:576px) {
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cn-decline,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cn-decline {
    border:1px solid transparent
  }
}
@media screen and (min-width:576px) {
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-link {
    line-height:1.5em;
    margin-left:10px;
    margin-top:4rem;
    max-width:calc(50% - 10px);
    position:absolute;
    right:0;
    z-index:100
  }
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cn-decline {
    max-width:calc(50% - 10px);
    margin-right:10px
  }
}
@media screen and (min-width:768px) {
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice),
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) {
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    background:transparent;
    bottom:unset;
    -webkit-box-shadow:none;
    box-shadow:none;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    height:0;
    margin:0;
    max-height:unset;
    max-width:unset;
    overflow:visible;
    padding:unset;
    position:relative;
    right:unset;
    top:50vh;
    width:100%
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body {
    -webkit-box-shadow:1px 2px 5px rgba(0,0,0,0.2);
    box-shadow:1px 2px 5px rgba(0,0,0,0.2);
    display:block;
    margin:0 auto;
    max-height:100vh;
    max-width:480px;
    overflow-y:auto;
    padding:20px 30px;
    right:unset;
    width:calc(100% - 40px)
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-link,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-link {
    width:100%
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn {
    background:#EFEFEF;
    display:inline-block;
    text-align:center;
    width:100%
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn.cm-btn-success,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn.cm-btn-success {
    background:#00AFCB;
    border-color:transparent;
    margin:.5em 0;
    -webkit-box-ordinal-group:0;
    -ms-flex-order:-1;
    order:-1;
    width:100%
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn.cm-btn-success:hover,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok .cm-btn.cm-btn-success:hover {
    background:#00AFCB;
    opacity:.7
  }
  .klaro.we_cookie_consent.notice--center .cookie-notice .cn-body .cn-ok .cm-btn.cm-btn-info,
  .klaro.we_cookie_consent.notice--center .cookie-notice .cn-body .cn-ok .cm-link.cm-learn-more {
    background:transparent
  }
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice .cn-body .cn-ok .cm-btn {
    border-color:#161f57;
    width:50%
  }
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice .cn-body .cn-ok .cm-btn.cm-btn-info,
  .klaro.we_cookie_consent.notice--center-floated .cookie-notice .cn-body .cn-ok .cm-link.cm-learn-more {
    background:transparent
  }
  .klaro.we_cookie_consent.notice--top .cookie-notice .cm-btn.cm-btn-success {
    -webkit-box-ordinal-group:3;
    -ms-flex-order:2;
    order:2
  }
}
.klaro.we_cookie_consent .cookie-modal {
  -webkit-box-shadow:1px 2px 5px rgba(0,0,0,0.2);
  box-shadow:1px 2px 5px rgba(0,0,0,0.2);
  z-index:9999
}
.klaro.we_cookie_consent .cookie-modal a:hover {
  opacity:0.7
}
.klaro.we_cookie_consent .cookie-modal .slider {
  -webkit-box-shadow:none;
  box-shadow:none
}
.klaro.we_cookie_consent .cookie-modal .cm-bg {
  background:rgba(0,0,0,0.7)
}
.klaro.we_cookie_consent .cookie-modal .cm-list-label {
  margin:0
}
.klaro.we_cookie_consent .cookie-modal .cm-list-title {
  font-size:1em;
  line-height:32px
}
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  padding-left:72px
}
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-toggle-all,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-toggle-all {
  padding-left:0
}
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-toggle-all .cm-list-label,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-toggle-all .cm-list-label {
  display:none
}
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left:-72px
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose {
  padding-left:92px;
  border-top:1px solid rgba(0,0,0,0.1);
  padding-top:.5em
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:checked+.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.required:checked+.cm-list-label .slider {
  background-color:#EFEFEF;
  top:2px;
  width:72px
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-label .slider::before {
  background-color:#b1d6e8;
  bottom:2px;
  height:24px;
  left:2px;
  width:24px;
  -webkit-transition:.2s ease 0s;
  transition:.2s ease 0s
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.half-checked:checked+.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.only-required+.cm-list-label .slider {
  background-color:#EFEFEF;
  opacity:0.8
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.half-checked:checked+.cm-list-label .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.only-required+.cm-list-label .slider::before {
  background-color:#63ADD1;
  -webkit-transform:translateX(100%);
  transform:translateX(100%)
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:checked+.cm-list-label .slider::before {
  background-color:green;
  -webkit-transform:translateX(calc(100% + 20px));
  transform:translateX(calc(100% + 20px))
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input+.cm-list-label .disabled .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:checked+.cm-list-label .disabled .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.required+.cm-list-label .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input.required:checked+.cm-list-label .slider::before {
  background-color:#C9C9C9;
  display:none
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:not(.only-required)+.cm-list-label>.cm-list-title+.cm-switch::before {
  // content:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-no.svg');
  height:16px;
  left:50px;
  line-height:32px;
  padding:2px;
  position:absolute;
  width:16px;
  z-index:10
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:checked+.cm-list-label>.cm-list-title+.cm-switch::before {
  content:"";
  left:8px;
  padding:2px;
  width:16px
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:not(.half-checked):checked+.cm-list-label>.cm-list-title+.cm-switch::before {
  // content:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-yes.svg')
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-label .cm-required ~ .cm-cswitch .slider,
.klaro.we_cookie_consent .cookie-modal .cm-purpose>.cm-list-input:checked+.cm-list-label .cm-required ~ .cm-switch .slider {
  // background:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-schloss.svg') no-repeat transparent;
  border-radius:unset;
  left:12px;
  width:24px
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose .cm-switch {
  cursor:pointer;
  left:0;
  position:absolute
}
.klaro.we_cookie_consent .cookie-modal .cm-purpose .cm-switch::before {
  -webkit-animation:flipYesNo .3s ease 0s normal forwards;
  animation:flipYesNo .3s ease 0s normal forwards
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:checked+.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.required:checked+.cm-list-label .slider {
  background-color:#EFEFEF;
  top:2px;
  width:52px
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-label .slider::before {
  background-color:#b1d6e8;
  bottom:2px;
  height:24px;
  left:2px;
  width:24px
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.half-checked:checked+.cm-list-label .slider,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.only-required:checked+.cm-list-label .slider {
  background-color:#EFEFEF;
  opacity:0.8
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.half-checked:checked+.cm-list-label .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.only-required:checked+.cm-list-label .slider::before {
  background-color:#63ADD1;
  -webkit-transform:translateX(100%);
  transform:translateX(100%)
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.only-required+.cm-list-label .slider::before {
  -webkit-transform:translateX(50%);
  transform:translateX(50%)
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:checked+.cm-list-label .slider::before {
  background-color:green;
  -webkit-transform:translateX(100%);
  transform:translateX(100%)
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input+.cm-list-label .disabled .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:checked+.cm-list-label .disabled .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.required+.cm-list-label .slider::before,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input.required:checked+.cm-list-label .slider::before {
  background-color:#C9C9C9;
  display:none
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:not(.only-required)+.cm-list-label>.cm-list-title+.cm-switch::before {
  // content:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-no.svg');
  line-height:32px;
  padding:2px;
  position:absolute;
  width:16px;
  z-index:10;
  left:30px;
  height:16px
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:checked+.cm-list-label>.cm-list-title+.cm-switch::before {
  // content:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-yes.svg');
  left:8px;
  padding:2px;
  width:16px
}
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-label .cm-required ~ .cm-cswitch .slider,
.klaro.we_cookie_consent .cookie-modal .cm-service>div>.cm-list-input:checked+.cm-list-label .cm-required ~ .cm-switch .slider {
  // background:url('../../../typo3conf/ext/we_cookie_consent/Resources/Public/Icons/icon-schloss.svg') no-repeat transparent;
  border-radius:unset;
  left:18px;
  width:20px
}
.klaro.we_cookie_consent .cookie-modal .cm-body>.cm-service>.cm-service>div>.cm-list-input:not(.only-required)+.cm-list-label>.cm-list-title+.cm-switch::before {
  padding-top:10px
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro {
  background:#fff;
  border-radius:0;
  bottom:220px;
  height:auto;
  max-height:calc(100vh - 220px);
  max-width:100%;
  overflow-x:hidden;
  padding:0;
  position:absolute;
  top:auto;
  -webkit-transform:inherit;
  transform:inherit;
  -webkit-transition:unset;
  transition:unset;
  width:100%
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .hide {
  background-color:#fff;
  color:#161f57;
  cursor:pointer;
  display:block !important;
  font-size:2em;
  height:60px;
  line-height:20px;
  overflow:hidden;
  padding:20px;
  top:0;
  right:0
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .hide svg {
  display:none
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .hide:after {
  content:'\00d7'
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-header {
  border-bottom:none;
  margin:20px;
  padding:0
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-header h1 {
  font-size:2em
}

.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-body {
  padding:0;
  margin:20px
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-body ul.cm-purposes li.cm-purpose {
  padding-left:92px
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top:1px solid rgba(0,0,0,0.1);
  padding-left:0
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all .cm-list-input,
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all .cm-list-label {
  display:none
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-toggle-all .cm-purpose-description {
  display:block
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer {
  background:#FFF;
  border:none;
  bottom:0;
  height:220px;
  left:0;
  margin:0;
  padding:20px;
  position:fixed;
  width:100%
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer>* {
  -ms-flex-line-pack:end;
  align-content:flex-end;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  height:calc(100% - 2em);
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  margin:0;
  width:100%;
  max-width:100%;
  padding:0
}
.klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-powered-by {
  display:block;
  height:40px;
  line-height:40px;
  margin:0;
  position:relative;
  right:unset;
  text-align:center;
  width:auto
}
.klaro.we_cookie_consent .cookie-modal .cm-btn {
  background:#EFEFEF;
  border-radius:0;
  border:1px solid transparent;
  -webkit-box-shadow:none;
  box-shadow:none;
  color:#161f57;
  margin:.5em auto;
  opacity:1;
  padding:.5em;
  text-align:center;
  width:100%
}
.klaro.we_cookie_consent .cookie-modal .cm-btn:hover {
  opacity:.7
}
.klaro.we_cookie_consent .cookie-modal .cm-btn-decline {
  -webkit-box-ordinal-group:3;
  -ms-flex-order:2;
  order:2
}
.klaro.we_cookie_consent .cookie-modal .cm-btn.cm-btn-info.cm-btn-accept {
  background:#00AFCB;
  color:#FFF;
  display:block;
  -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1;
  text-decoration:none;
  width:100%
}
.klaro.we_cookie_consent .cookie-modal .cm-btn.cm-btn-info.cm-btn-accept:hover {
  opacity:.7
}
.klaro.we_cookie_consent .cookie-modal .cm-btn.cm-btn-accept-all {
  background:#EFEFEF;
  -webkit-box-ordinal-group:4;
  -ms-flex-order:3;
  order:3
}
.klaro.we_cookie_consent .cookie-modal .cm-btn.cm-btn-sm {
  padding:.5em 1.5em;
  font-size:1em
}
.klaro.we_cookie_consent .cookie-modal .cm-toggle-all .cm-purpose-description {
  display:none
}
@media screen and (min-width:576px) {
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro {
    max-height:calc(100vh - 150px);
    bottom:150px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer {
    height:150px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer>* {
    -ms-flex-wrap:unset;
    flex-wrap:unset;
    height:auto;
    margin:.5em -10px;
    max-width:calc(100% + 20px);
    width:calc(100% + 20px)
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer>* .cm-btn {
    margin:.5em 10px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-powered-by {
    float:right;
    height:50px;
    line-height:50px
  }
}
@media screen and (min-width:768px) {
  .klaro.we_cookie_consent .cookie-modal .cm-modal {
    max-height:100vh;
    bottom:0
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .hide {
    right:10px;
    top:5px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-header h1 {
    font-size:2em
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer {
    margin:0;
    padding:20px 0;
    position:relative;
    width:100%
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer>* {
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:end
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-header {
    margin:20px 30px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body {
    margin:20px 30px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-btn {
    padding:1em 1.5em
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-btn:first-child:last-child {
    min-width:240px;
    position:absolute;
    width:25%
  }
}
@media screen and (min-width:1024px) {
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro.cm-klaro {
    max-height:calc(100vh - 100px);
    bottom:100px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer {
    height:100px;
    max-width:100%;
    padding:20px 10px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer>* {
    justify-content:left;
    margin:.5em 0;
    max-width:75%;
    position:absolute
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-btn {
    width:25%;
    min-width:240px
  }
  .klaro.we_cookie_consent .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-powered-by {
    margin:15px 10px;
    position:absolute;
    right:10px
  }
}
@media screen and (min-width:768px) {
  .klaro.we_cookie_consent.notice--center,
  .klaro.we_cookie_consent.notice--center-floated {
    height:0;
    overflow:visible;
    position:fixed;
    top:0;
    width:100%;
    z-index:10000
  }
}
@media screen and (min-width:768px) {
  .klaro.we_cookie_consent.notice--center .cookie-modal,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal {
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    background:unset;
    bottom:unset;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    max-height:unset;
    max-width:unset;
    padding:unset;
    right:unset;
    overflow:visible;
    top:0
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro {
    bottom:auto;
    -webkit-box-shadow:1px 2px 5px rgba(0,0,0,0.2);
    box-shadow:1px 2px 5px rgba(0,0,0,0.2);
    height:auto;
    max-height:100vh;
    max-width:768px;
    overflow-y:auto;
    padding:20px;
    position:relative;
    top:auto;
    width:100%
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal .cm-footer,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro .cm-footer,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal .cm-footer,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro .cm-footer {
    margin:0 -20px -20px -20px;
    position:relative;
    width:calc(100% + 40px)
  }
}
@media screen and (min-width:1024px) {
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro {
    bottom:auto;
    max-height:calc(100vh - 150px)
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal .cm-footer,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro .cm-footer,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal .cm-footer,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro .cm-footer {
    height:150px;
    margin:0 -20px -20px;
    max-width:calc(100% + 40px);
    padding-bottom:0;
    width:calc(100% + 40px)
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal .cm-footer>*,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro .cm-footer>*,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal .cm-footer>*,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro .cm-footer>* {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin:0 auto;
    max-width:100%;
    position:relative;
    width:auto
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal .cm-footer .cm-btn,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-btn,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal .cm-footer .cm-btn,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-btn {
    width:100%;
    min-width:auto
  }
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal .cm-footer .cm-btn:first-child:last-child,
  .klaro.we_cookie_consent.notice--center .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-btn:first-child:last-child,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal .cm-footer .cm-btn:first-child:last-child,
  .klaro.we_cookie_consent.notice--center-floated .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-btn:first-child:last-child {
    width:calc(100% - 20px);
    position:relative
  }
}
@-webkit-keyframes flipYesNo {
  0% {
    opacity:0
  }
  20% {
    opacity:0
  }
  80% {
    opacity:0
  }
  100% {
    opacity:1
  }
}
@keyframes flipYesNo {
  0% {
    opacity:0
  }
  20% {
    opacity:0
  }
  80% {
    opacity:0
  }
  100% {
    opacity:1
  }
}
.klaro.we_cookie_consent .cookie-notice h1,
.klaro.we_cookie_consent .cookie-notice h2,
.klaro.we_cookie_consent .cookie-notice li,
.klaro.we_cookie_consent .cookie-notice p,
.klaro.we_cookie_consent .cookie-notice a,
.klaro.we_cookie_consent .cookie-notice strong,
.klaro.we_cookie_consent .cookie-notice ul,
.klaro.we_cookie_consent .cookie-modal h1,
.klaro.we_cookie_consent .cookie-modal h2,
.klaro.we_cookie_consent .cookie-modal li,
.klaro.we_cookie_consent .cookie-modal p,
.klaro.we_cookie_consent .cookie-modal a,
.klaro.we_cookie_consent .cookie-modal strong,
.klaro.we_cookie_consent .cookie-modal ul {
  color:#161f57;
  font-size:1em
}
.klaro.we_cookie_consent .cookie-notice a,
.klaro.we_cookie_consent .cookie-modal a {
  font-weight:700;
  text-decoration:underline
}
.klaro.cm-as-context-notice {
  padding:1em
}
.klaro.cm-as-context-notice .context-notice {
  border:0;
  border-radius:0;
  background:#FFF
}
.klaro.cm-as-context-notice .context-notice p {
  color:#161f57
}
.klaro.cm-as-context-notice .context-notice .cm-buttons {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:2em;
  width:100%
}
.klaro.cm-as-context-notice .context-notice .cm-buttons .cm-btn {
  border-radius:0;
  display:block;
  -webkit-box-flex:0;
  -ms-flex:0 0 50%;
  flex:0 0 50%;
  max-width:calc(50% - 20px);
  margin:0 auto;
  padding:1em 1.5em
}
.klaro.cm-as-context-notice .context-notice .cm-buttons .cm-btn.cm-btn-success {
  background-color:#00AFCB
}
.klaro.cm-as-context-notice .context-notice .cm-buttons .cm-btn.cm-btn-success-var {
  background-color:#00AFCB
}
.klaro.cm-as-context-notice .context-notice .cm-buttons .cm-btn:hover {
  opacity:.7
}
.tx-we-cookie-consent .service__headline {
  font-weight:bold
}
.tx-we-cookie-consent h3,
.tx-we-cookie-consent .h3 {
  color:#575757;
  font-size:1.55rem;
  font-weight:400
}
.tx-we-cookie-consent caption {
  display:none
}
.tx-we-cookie-consent .table {
  margin-bottom:2rem;
  margin-top:2rem
}
.tx-we-cookie-consent .table thead th {
  border-bottom:1px solid #ABABAB;
  font-size:0.95rem;
  padding-bottom:0;
  text-transform:uppercase
}
.tx-we-cookie-consent .table th:last-of-type,
.tx-we-cookie-consent .table td:last-of-type {
  text-align:right
}
.tx-we-cookie-consent .table-striped tbody tr:nth-of-type(2n+1) {
  background-color:#efefef
}
.tx-we-cookie-consent .table-hover tbody tr:hover {
  background-color:rgba(0,0,0,0.05)
}
.tx-we-cookie-consent a.btn-primary {
  color:#161f57;
  background-color:#63ADD1;
  border-color:#63ADD1
}
.tx-we-cookie-consent .btn-primary:focus,
.tx-we-cookie-consent .btn-primary.focus,
.tx-we-cookie-consent .btn-primary:not(:disabled):not(.disabled):active:focus,
.tx-we-cookie-consent .btn-primary:not(:disabled):not(.disabled).active:focus,
.tx-we-cookie-consent .show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow:none;
  box-shadow:none
}
.tx-we-cookie-consent .btn-primary:hover,
.tx-we-cookie-consent .btn-primary:not(:disabled):not(.disabled):active,
.tx-we-cookie-consent .btn-primary:not(:disabled):not(.disabled).active,
.tx-we-cookie-consent .show>.btn-primary.dropdown-toggle {
  opacity:0.7
}
.tx-we-cookie-consent .btn-lg,
.tx-we-cookie-consent .btn-group-lg>.btn,
.tx-we-cookie-consent .btn-group-lg>.cc-btn {
  border-radius:4px
}
.tx-we-cookie-consent .table th,
.tx-we-cookie-consent .table td {
  border-top:none;
  padding:.5rem
}
.tx-we-cookie-consent .table th p,
.tx-we-cookie-consent .table td p {
  margin:0
}
.tx-we-cookie-consent .table-hover tbody tr:hover {
  background-color:transparent
}
.tx-we-cookie-consent a.btn-primary {
  border-radius:0;
  text-decoration:none;
  display:inline-block
}



.klaro {
  .cookie-modal {
    .cm-modal.cm-klaro {
      border: 0px solid red;
      border-right: 0px !important;
      margin: 0 !important;
      height: 100% !important;
      width: 90% !important;
      max-width: none !important;
      background-color: #fff !important;
      left:30px !important;
      .cm-header {
        h1 {
          color: color("blue");
        }
        p {
          span {
            color: color("blue");
          }
        }
      }

      .cm-footer {
        .cm-footer-buttons {
          .cm-btn {
            border-radius: 0px !important;
            width: 33%;
            padding: 1em 1.5em;
            margin: .5em 10px;
          }

          .cm-btn-decline {
            color: theme-color("primary");
            background-color: #f4f4f4;
          }
          .cm-btn-accept {
            color: $white;
            background-color: theme-color("primary");;
          }
          .cm-btn-accept-all {
            color: $white;
            background-color: #009cc8;
          }
        }
      }

      .cm-body {



        .cm-list-description {
          color: color("blue");
        }
        .cm-purpose {
          color: color("blue");
          .cm-list-title {
            color: color("blue");
          }
        }
      }
    }
  }
}

.klaro .cookie-modal a, .klaro .context-notice a, .klaro .cookie-notice a {
  color: #008000;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #008000 !important;
}
.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #a6c9a6 !important;
}
